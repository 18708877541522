/* roboto-300 - latin_latin-ext */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('++theme++ledprofessional-theme/fonts/roboto-v30-latin_latin-ext-300.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
        url('++theme++ledprofessional-theme/fonts/roboto-v30-latin_latin-ext-300.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* roboto-regular - latin_latin-ext */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('++theme++ledprofessional-theme/fonts/roboto-v30-latin_latin-ext-regular.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
        url('++theme++ledprofessional-theme/fonts/roboto-v30-latin_latin-ext-regular.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* roboto-500 - latin_latin-ext */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('++theme++ledprofessional-theme/fonts/roboto-v30-latin_latin-ext-500.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
        url('++theme++ledprofessional-theme/fonts/roboto-v30-latin_latin-ext-500.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* roboto-700 - latin_latin-ext */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('++theme++ledprofessional-theme/fonts/roboto-v30-latin_latin-ext-700.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
        url('++theme++ledprofessional-theme/fonts/roboto-v30-latin_latin-ext-700.ttf') format('truetype');
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

// for the toolbar
$roboto-base-path: "/++theme++barceloneta/roboto/";
