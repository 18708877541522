// toolbar fix
#edit-zone.bg-dark .nav-link {
    color:var(--bs-body-bg);
}

header > h1 {
    color:$primary;
    font-weight:400;
}

.documentDescription {
    color: $gray-600;
    font-weight: $font-weight-bold;
}

#content-header {
    position:relative;
    z-index:2000;

    #portal-logo {
        margin-top:0;

        > a {
            border-right:solid 1px white;
        }

        img {
            width:150px;
            padding:0.5rem 1rem 0 1rem;
            margin-bottom:-1.2rem;
            margin-top:0.6rem;
        }
    }
}

.plone-modal-wrapper {
    z-index:2501 !important;
}
.plone-modal-backdrop {
    z-index:2500 !important;
}

#portal-globalnav-wrapper {
    margin:0.2rem 0;

    .navbar-nav {
        @include media-breakpoint-up(lg) {
            margin-left:140px;
        }

        li {
            background-color: white;
            &.inPath>a,
            &.current>a,
            &.selected>a {

                &,
                &:hover,
                &:focus {
                    color: $primary;
                    background-color:white;
                }
            }

            a {
                border-width:1px;
                border-style: solid;
                border-color:transparent;

                &:hover,
                &:focus {
                  text-decoration: none;
                  background-color: white;
                  border-color:black;
                }

            }
        }
    }
}

#portal-breadcrumbs {
    font-size: $font-size-sm;

    &, .breadcrumb {
        margin-bottom:0
    }
}

/* banners */
@include media-breakpoint-up(lg) {
    body > div.outer-wrapper, #portal-footer {
        padding-right:170px;
    }

    #rightoutside-banners {
        position:absolute;
        right:0;
        top:0;
        width: 150px;
        padding-top:150px;
    }
}

#portal-header-banner {
    @include media-breakpoint-down(md) {
        width:100%;
        margin:0;
        overflow:hidden;
    }
}

#portal-column-one .portletStaticText .card-body {
    padding:0;
}

/* portlet cards */

.pat-collectionfilter {
    margin:0;
}

.portlet, .managedPortlet {
    border-color:$primary;

    .card-header {
        &, a {
            color:#fff
        }
    }

    .card-body > ul {
        > li {
            border-top-color:$primary;
            a {
                color: var(--bs-body-color);
            }
        }
    }
    .card-footer {
        background-color:transparent;
        text-align: right;
        border-top:1px solid $primary;
    }

    .portletItemDetails {
        font-size:$font-size-sm;
        white-space:nowrap;
    }
}

.portlet-alternate-listing-doc {
    .card-body {
        background-color:#f0f0f0;
        h4 a {
            color:var(--bs-body-color);
        }
    }
}
.portlet-alternate-listing-banner {
    margin-bottom:$spacer;
}

.portlet, .tileItem {
    h1, h2, h3, h4, h5, h6 {
        font-size:$font-size-base;
        a {
            color:$headings-color;
        }
    }
}

/* GLD listing */

.list-group.gld_parameters {
    &, .list-group-item {
        border:none;
    }
}

/* responsive text images */
#section-text img {
    max-width:100%;
    height:auto;
}

/* pagepeel */
#portal-pagepeel {
    @include media-breakpoint-up(lg) {
        position: absolute;
        top:0;
        right:0;
        z-index:3000;
    }

    cursor:pointer;
    margin:0 auto;
    box-shadow:0 0 0.5em #000000;
    position:relative;

    .peelCorner {
        width: 30px; height: 30px;
        z-index: 99;
        position: absolute;
        left: 0; bottom: 0;
        -ms-interpolation-mode: bicubic;
    }
    .peelAd {
        overflow:hidden;
        left: 0; top: 0;
    }
    .hidden {
        display:none;
    }
}

#portal-searchbox {
    order:initial;
    width:auto;
    margin-left:$spacer * .5;
    margin-top:0;

    .pat-livesearch {
        width:100%;
    }
    [type="text"] {
        width:100%;
        min-height:1rem;
        padding:0 0.5rem;
        margin:2px 0;
    }
    [type="submit"] {
        display:none;
    }
    .livesearch-results {
        --bs-border-radius: 0;
        min-width:350px !important;
        left:auto !important;
        right:$spacer;
        margin-top:$spacer;
    }
}
#portal-searchbox, #portal-siteactions {
    @include media-breakpoint-up(md) {
        margin-right:2px;
    }
}

#portal-siteactions, #portal-anontools {
    margin-left:0;
    padding-top:0;

    @include media-breakpoint-down(lg) {
        display:none;
    }

    > ul {
        margin:0;
        padding:0;
        li {
            display:inline-block;
            margin-left:1rem;
            font-size:$font-size-sm;

            a {
                color:white;
                text-decoration: underline;;
            }
        }
    }
}

#portal-footer-wrapper {
    text-align:left;
    font-size:$font-size-sm;
    a {
        color:$footer-color;
    }
    table {
        --bs-table-bg: transparent;
    }
}


/* MOSAIC */
.mosaic-toolbar {
    z-index:3030;
}

.mosaic-tile-content {
    margin-bottom: $spacer * 2.5;

    .tileHeader {
        background-color:$primary;
        padding: $card-spacer-y $card-spacer-x;

        &, a, h2 {
            color:white;
            font-weight:$font-weight-base;
            font-size:$font-size-base;
            margin:0
        }
    }

    .tileOuterFooter {
        padding: $card-spacer-y $card-spacer-x;
        text-align: right;
    }

    .tileItem {
        margin-bottom:$spacer;
        &:last-child {
            margin-bottom:0;
        }
        .tileBody, .tileFooter {
            margin-bottom:$spacer * .25;
        }
        .tileFooter {
            border-bottom:solid 1px $primary;
            padding-bottom: $spacer * .5;
        }
    }
    .carousel {
        .top-slider {
            max-height:380px;
            object-fit: cover;
            @include media-breakpoint-down(md) {
                max-height:250px;
            }
        }

        .carousel-caption {
            display:block;
            bottom:0;
            left:0;
            width:100%;
            background:rgba(0,0,0,0.65);
            padding:$spacer * .5 $spacer;

            h5 {
                font-size:$h1-font-size;
                color:white;
                margin-bottom:0;
            }
        }
    }

}

/* carousel tile*/
.mosaic-collective\.tiles\.carousel-tile .tileDescription {
    display:none;
}


body.mce-fullscreen {
    #mainnavigation-wrapper, #rightoutside-banners {
        display:none;
    }
}

.addthis_sharing_toolbox {
    float:right;
}

.callout {
    background: #eee;
    padding: 1em;
    border-left: 1em solid #ccc;
    clear: both;
}


/*responsive header*/
@include media-breakpoint-down(lg) {
    #content-header > div {
        padding-right:var(--bs-gutter-x,.75rem);
        padding-left:var(--bs-gutter-x,.75rem);
    }
    #content-header > div, #portal-top {
        flex-direction:column-reverse;
    }
    #portal-searchbox {
        margin:$spacer 0;
        width:100%;
        max-width:none;
    }
}

@include media-breakpoint-down(xxl) {
    #portal-header-banner {
        max-width:100%;
        overflow:hidden;
    }
}
@include media-breakpoint-only(xl) {
    #portal-top {
        max-width:66%;
    }
}
@include media-breakpoint-only(lg) {
    #portal-top {
        max-width:50%;
    }
}

// banner mobile fix
iframe {
    max-width:100%;
}

.image-left {
    float:left;
    margin:.5em 1em .5em 0;
}
